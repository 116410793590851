@media only screen and (max-width: 950px) {
  .download-list .MuiListItem-secondaryAction {
    padding-bottom: 70px;
  }
  .download-list .MuiListItemSecondaryAction-root {
    bottom: 0;
    left: 15px;
    top: unset;
  }
}
