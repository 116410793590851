/* footer */
footer .MuiContainer-root {
  display: flex;
  justify-content: space-between;
  padding: 3rem 0;
}
footer .separator {
  display: inline-block;
  margin: 0 0.5rem;
  margin-top: -1em;
}
footer span {
  font-size: initial;
  color: inherit;
}

/*  < tablet */
@media only screen and (max-width: 768px) {
  footer .MuiContainer-root {
    flex-direction: column;
    line-height: 2rem;
    border-top: 1px solid lightgray;
  }
  .MuiAppBar-positionSticky {
    position: unset;
  }
}

/* < laptop */
@media only screen and (max-width: 990px) {
  footer .MuiContainer-root {
    font-size: 8px;
    padding: 2em;
  }
}
