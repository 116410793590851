.payment-form .MuiCardContent-root {
  padding: 10px;
}

.payment-form {
  background: unset;
  max-width: 450px;
}

.payment-form fieldset {
  border: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
}

.payment-form .label {
  margin-bottom: var(--spc);
  font-size: 13px;
  color: var(--black);
}

.payment-form .field {
  border: 1px solid var(--grey-light);
  border-radius: var(--spc);
  padding: 0.8rem;
  margin-bottom: 1.2rem;
}

.payment-form fieldset[name="cvv"],
.payment-form fieldset[name="expiry"] {
  display: inline-block;
  width: 50%;
}
.payment-form fieldset[name="expiry"] {
  padding-right: 1rem;
}

.payment-form .field.name {
  border: none;
  margin: 0;
  padding: 0;
}
.payment-form .field.name input::placeholder {
  color: #757575;
  opacity: unset;
}
.payment-form.standalone .field.name {
  margin: 20px 0;
}

.payment-form.standalone {
  border: none;
}
.payment-form.standalone .supported-cards {
  background: var(--grey-lighter);
  border: 1px solid var(--grey-light);
  padding: 10px;
  text-align: center;
  margin-bottom: 40px;
}
.payment-form .supported-cards p {
  margin: 10px 0;
}
.supported-cards .icons img {
  height: 35px;
  margin-right: 10px;
}

.payment-form .error-msg {
  margin-bottom: 40px;
}

.field.number.unsupported {
  border: 1px solid var(--error);
  color: var(--error);
  position: relative;
}
.field.number.unsupported:before {
  content: "Card type not supported";
  position: absolute;
  font-size: 13px;
  bottom: -20px;
  left: 0;
}
.field.number.unsupported:after {
  content: url("../images/warning.svg");
  position: absolute;
  right: 5px;
  bottom: 10px;
  background: white;
  width: 30px;
}
