.credit-card {
  border-radius: 6px;
  margin: 2rem auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  background-color: #d8d8d8;
  background-image: url("../images/card-bg-datasphere.png");
  background-size: cover;

  width: 100%;
  padding-top: 30%; /* maintain aspect ratio */
}

.card-number {
  font-size: 1.4rem;
  font-weight: 700;
}
.card-expiry,
.card-name {
}
