.invoices {
  border: 1px solid var(--grey-light);
  min-width: 768px;
}

.invoices .MuiTableRow-root:nth-child(2n) {
  background: #fff;
}
.invoices .MuiTableRow-root:nth-child(2n + 1) {
  background: #f2f5f8;
}

.invoices .MuiTableCell-head {
  background: var(--black);
  color: var(--white);
  font-weight: 700;
  padding: 0.5rem;
}

.invoices .MuiTableCell-head,
.invoices .MuiTableCell-body {
  font-size: 14px;
}

.invoices .MuiTableCell-root.amount {
  font-weight: 700;
}

.invoices .MuiTableCell-root:first-child {
  padding: 0 2rem;
}
.invoices .MuiTableCell-root {
  padding: 0.5rem;
}
