.order-summary {
  max-width: 450px;
}
.order-summary .btn-subscribe {
  margin: calc(var(--spc) * 7) 0;
  text-transform: initial;
  font-weight: 700;
  border-width: 2px;
  border-color: var(--primary);
}
.order-summary .btn-subscribe.Mui-disabled {
  background: var(--grey-light);
  color: var(--grey-dark);
  border-color: transparent;
}

.border {
  border: 1px solid var(--grey-light);
  border-radius: var(--spc);
  border-bottom: none;
  overflow: hidden;
}

.payment-options li {
  margin: unset;
  background: var(--primary-light);
  padding: calc(var(--spc) * 4) calc(var(--spc) * 7);
  display: flex;
  align-items: center;
}

.payment-options .option {
  border-bottom: 1px solid var(--grey-light);
  font-weight: normal;
}

.payment-options input[type="radio"] {
  margin: 0;
  margin-right: 1rem;
  border-width: 2px;
  width: calc(var(--spc) * 4);
  height: calc(var(--spc) * 4);
}
.payment-options input[type="text"] {
  font-size: 14px;
  height: 1.2em;
}

.payment-options .payment-form,
.payment-options .company-form,
.order-summary .required-card-form {
  background: white;
  padding: calc(var(--spc) * 4) calc(var(--spc) * 7);
  border-bottom: 1px solid var(--grey-light);
}

.order-summary .MuiInputBase-root,
.card-form fieldset .field {
  border: 1px solid var(--grey-light);
  border-radius: var(--spc);
  padding: 1rem;
  margin-bottom: 1rem;
  background: transparent;
}
.payment-form.inset .supported-cards {
  margin-bottom: 2rem;
}

.error-msg {
  margin-bottom: 40px;
}
