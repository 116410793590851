.MuiInputBase-root {
  background: white;
  border: 1px solid var(--grey-light);
  border-radius: var(--spc);
  padding: calc(var(--spc) * 2) calc(var(--spc) * 4);
  height: calc(var(--spc) * 10);
  font-size: 14px;
}
.MuiInputBase-root.Mui-disabled {
  background: transparent;
}
.MuiInput-underline:before,
.MuiInput-underline:after {
  border: none;
  transition: none;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border: unset;
}
.MuiInputLabel-root {
  text-align: left;
}

.MuiFormLabel-root {
  font-weight: 400;
  font-size: 14px;
  margin: calc(var(--spc) * 2) 0;
  color: var(--text-label);
}

.MuiFormHelperText-root.warning {
  margin-bottom: 10px;
  font-size: 13px;
  color: var(--error);
}

.MuiInput-root.Mui-error {
  border-color: var(--error);
}
