.account-menu {
  display: flex;
  border-bottom: 1px solid var(--grey-light);
  margin: 1rem 0 2rem 0;
  position: sticky;
}

.account-menu .tab-link {
  color: var(--grey-dark);
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 2rem;
  margin-right: 20px;
  text-decoration: unset;
}

.account-menu .tab-link.tab-active {
  border-bottom: 2px solid var(--primary);
  color: var(--primary);
}

.page-title {
  margin: 4rem 0 3rem 0;
  font-weight: 700;
  color: var(--black);
}

.plan-type-title {
  margin: 3rem 0;
  font-weight: 700;
  font-size: 0.8rem;
}

.account-dialog .MuiDialogActions-root.vertical {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.account-dialog .MuiDialogActions-root.vertical .MuiButton-label {
  justify-content: initial;
}
.account-dialog .MuiDialogTitle-root {
  padding-bottom: 5px;
}
.account-dialog .MuiDialogContent-root {
  margin-bottom: 20px;
}
